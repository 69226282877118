.you .row {
    position: relative;
}

.you .step > div {
    display: flex;
}

.you .steps {
    position: relative;
    margin-top: 65px;
}

.you .step > div > p {
    margin-right: 10px;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    font-family: "SAPBook", Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.you .step > div > p:first-child {
    color: var(--color-green);
}

.you .steps > h4 {
    margin-top: 0;
    text-transform: uppercase;
    font-size: var(--font-size-body);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
    letter-spacing: 0.08rem;
    color: var(--color-orange) !important;
}

.you .step > h5 {
    margin-bottom: 10px;
    font-family: "SAPRegular", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: var(--font-size-body);
    max-width: 75%;
}

.you .step > h5 > span {
    margin-right: 5px;
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
    color: black;
}

.you .steps .step {
    margin-bottom: 18px;
    opacity: 0;
}

.you .steps .step p {
    position: relative;
}

.you .steps .step p:first-child::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 25px;
    height: 20px;
    width: 6px;
    background: radial-gradient(#000000, #000000 1px, transparent 1px, transparent);
    background-size: 6px 5px;
    background-repeat-y: repeat;
    background-repeat-x: no-repeat;

}

.you .steps .step p:last-child::before {
    content: '';
    position: absolute;
    left: 32px;
    top: 10px;   
    height: 6px;
    --grow-height: calc((1140px / 2) + 7vw);
    background: radial-gradient(#000000, #000000 1px, transparent 1px, transparent);
    background-size: 6px 5px;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
}

.steps.active .step p:last-child::before {
    -webkit-animation: growRight ease-in 1s;
    animation: growRight ease-in 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.steps.active .step p:first-child:before {
    opacity: 0;
    -webkit-animation: growDown ease-in 1s;
    animation: growDown ease-in 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: .75s;
}

.steps.active .step {
    opacity: 0;
    -webkit-animation: fading ease-in 0.5s;
    animation: fading ease-in 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

.steps.active .step.one {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.steps.active .step.one p:first-child::before {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.steps.active .step.one p:last-child::before {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.steps.active .step.two {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.steps.active .step.two p:first-child::before {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.steps.active .step.two p:last-child::before {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.steps.active .step.three {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.steps.active .step.three p:first-child::before {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

.you.active .steps .step.three p:last-child::before {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.steps.active .step.four {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
}

.steps.active .step.four p:first-child::before {
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}

.steps.active .step.four p:last-child::before {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
}

.steps.active .step.five {
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
}

.steps.active .step.five p:first-child::before {
    -webkit-animation-delay: 9s;
    animation-delay: 9s;
}

.steps.active .step.five p:last-child::before {
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
    left: 39px;
}

.steps.active .step.last {
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
}

.steps.active .step.last h5 {
    color: var(--color-green);
}

.answer-no {
    position: absolute;
    right: -63%;
    top: 50%;
    width: 250px;
    opacity: 0;
}

.steps.active ~ .answer-no {
    -webkit-animation: slideInOffset ease-in 1s;
    animation: slideInOffset ease-in 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 1.5s;
}

.steps.active ~ .answer-no div > p:first-child {
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
    letter-spacing: 0.08rem;
    font-weight: 400;
}

.steps.active::before {
    content: '';
    position: absolute;
    top: 70px;
    right: -27%;
    border-left: 1px solid black;
    --grow-height: calc(100% - 115px);
    width: 1px;
    
    opacity: 0;
    -webkit-animation: growDownLine ease-in 0.5s;
    animation: growDownLine ease-in 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    animation-duration: 1s;
    animation-delay: 0.6s;
}

@media (min-width: 2560px) {
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) + 5.5vw);
    }
}

@media (max-width: 1600px) {
    .answer-no {
        position: relative;
        top: -16px;
        right: 0;
        width: 100%;
        text-align: right;
    }
    
    .answer-no div {
        width: 55%;
        display: inline-block;
        margin-right: 69px;
    }
    
    .steps.active::before {
        right: 9%;
        --grow-height: calc(100% - 6%);
    }  
    
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) + 16vw);
    }
}


@media (max-width: 1440px) { 
    .steps.active::before {
        --grow-height: calc(100% - 7%);
    }   
    
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) + 18vw);
    }
}

@media (max-width: 1241px) {
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) + 21vw);
    }
}

@media (max-width: 1200px) {
    .answer-no div {
        width: 60%;
        margin-right: 0;
    }
}

@media (max-width: 1199px) {
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) + 9vw);
    }
}

@media (max-width: 1024px) {
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) + 10vw);
    }
    
    .answer-no {
        width: 100%;
        margin-right: 52px;
    }
    
    .answer-no > div {
        margin-right: 52px;
    }
}

@media (max-width: 991px) {
    .answer-no > div {
        width: 100%;
        right: 0;
        margin-right: 0;
    }
    
    .answer-no p:first-child {
        margin-bottom: 8px;
    }
    
    .steps.active::before {
        right: 3%;
        --grow-height: calc(100% - 5%);
    }   
    
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) - 10vw);
    }
}

@media (max-width: 767px) {
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) - 31vw);
    }
}

@media (max-width: 576px) {
    .you .steps .step p:last-child::before {
        --grow-height: calc(100vw - 223px);
    }
}

@media (max-width: 320px) {
    .answer-no {
        width: 100%;
        right: -4%;
    }
    
    .you .steps .step p:last-child::before {
        --grow-height: calc((1140px / 2) - 145vw);
    }
}