.highlight {
    position: relative;
    margin: 50px 0;
    overflow: hidden;
    max-height: 654px;
}

#highlight-business {
    height: 641px;
}

.highlight.collide figcaption > h4 {
    color: white !important;
}

.highlight.collide .circle-bg--orange {
    background-color: var(--color-orange);
    animation: bgScaleLeft ease-in 1.25s;
    position: absolute;
    width: 100vw;
    height: 654px;
    transform-origin: center;
    opacity: 0.95;
    --left-position: 18%;
}

.highlight .highlight-orange--image {
    height: 654px;
}

.highlight .highlight-orange--image img {
    object-fit: cover;
    object-position: center;
    height: inherit;
    width: 100%;
}

.highlight .highlight-blue--image {
    height: 641px;
}

.highlight .highlight-blue--image img {
    object-fit: cover;
    object-position: center;
    height: inherit;
    width: 100%;
}

.highlight.collide .circle-bg--blue {
    background-color: var(--color-light-blue);
    animation: bgScaleRight ease-in 1.5s;
    position: absolute;
    width: 100vw;
    height: 641px;
    transform-origin: center;
    opacity: 0.95;
    --right-position: 29%;
}

.highlight.collide .highlight-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    --fade-opacity: 1;
    animation: 2s ease 0s normal forwards 1 fadeIn;
    z-index: 999;
}

.highlight.collide .highlight-video video {
    height: auto;
}

.highlight.collide .highlight-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 999;
    --fade-opacity: 1;
    animation: 2s ease 0s normal forwards 1 fadeIn;
}

.highlight .highlight-video {
    opacity: 0;
}

.highlight .highlight-content {
    opacity: 0;
}

.highlight figcaption p, .highlight figcaption p > span {
    color: white;
}

@media (min-width: 2560px) {
    .highlight.collide .circle-bg--orange {
        --left-position: 26%;
	}
    
    .highlight.collide .circle-bg--blue {
        --right-position: 20%;
	}
}

@media (max-width: 1600px) {
	.highlight.collide .circle-bg--orange {
        width: 100vw;
        --left-position: 12%;
	}
    
    .highlight.collide .circle-bg--blue {
        width: 100vw;
        --right-position: 33%;
	}
}


@media (max-width: 1440px) {    
    .highlight.collide .circle-bg--orange {
        --left-position: 8%;
	}
    
    .highlight.collide .circle-bg--blue {
        --right-position: 18%;
	}
    
    video {
        height: auto;
    }
}

@media (max-width: 1024px) {
    .highlight.collide .circle-bg--orange {
        --left-position: 1%;
	}
    
    .highlight.collide .circle-bg--blue {
        --right-position: 15%;
	}
    
    .highlight-video {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 991px) {
    .highlight {
        max-height: 405px;
    }
    
    .highlight .highlight-orange--image {
        height: 400px;
        background-position: center;
    }
    
    .highlight .highlight-blue--image {
        height: 405px;
    }
    
    .highlight.collide .circle-bg--orange {
        --left-position: 2%;
        height: 400px;
    }
    
    .highlight.collide .circle-bg--blue {
        --right-position: 14%;
        height: 405px;
    }
    
    .col-8 {
        flex: 75%;
        max-width: 75%;
    }
}

@media (max-width: 576px) {
    .highlight.collide .circle-bg--blue {
        --right-position: 0%;
    }
    
    video {
        height: 400px !important;
    }
}