  .nav-container {
    position: relative;
  }

  @media (max-width: 1199px) {
    .nav-container {
      z-index: 2222;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  .bg_black.navbar {
    max-width: 100vw;
  }
  
  .navbar-toggler {
    position: absolute;
    right: 60px;
    top: 20px;
    display: block;
    border: none !important;
    outline: none;
    z-index: 999;
  }
  
  .navbar-toggler:focus {
    outline: unset;
  }
  
  .navbar-toggler[aria-expanded="true"] span:nth-child(1) {
    background-color: transparent;
  }
  
  .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    transform: rotate(45deg) translate(5px, 5px);
    transition: 0.5s;
  }
  
  .navbar-toggler[aria-expanded="true"] span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -8px);
    transition: 0.5s;
  }
  
  .navbar-toggler span {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 30px;
    height: 1px;
    background-color: white;
    margin-bottom: 8px;
    display: block;
    backface-visibility: hidden;
    transition: 0.5s;
  }
  
  .navbar-toggler span:nth-child(2) {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 1px;
    background-color: white;
    margin-bottom: 8px;
    display: block;
    backface-visibility: hidden;
    transition: 0.5s;
  }
  
  .navbar-toggler span:nth-child(3) {
    content: '';
    position: absolute;
    left: 0;
    top: 18px;
    width: 30px;
    height: 1px;
    background-color: white;
    margin-bottom: 8px;
    display: block;
    backface-visibility: hidden;
    transition: 0.5s;
  }
  
  .navbar-toggler span:nth-child(3) {
    margin-bottom: 0;
  }

  .navbar.nav {
    position: absolute;
    right: -45px;
    z-index: 99;
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    animation: navOut 0.5s ease;
  }
  
  @media (max-width: 1200px) {
    .navbar.nav {
      right: 35px;
    }
  }
  
  @media (max-width: 1199px) {
    .navbar.nav {
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      padding-top: 0;
    }
    
    .bg-nav.open::after {
      content: '';
      background-color: var(--color-light-blue);
      animation: bgScaleNav ease-in 1s;
      position: absolute;
      width: 100vw;
      height: 100vh;
      opacity: .95;
      z-index: -1;
      top: 0;
      right: 0;
      /* left: 0; */
    }
    
    .bg-nav::after {
      content: '';
      background-color: var(--color-light-blue);
      animation: bgScaleNavBack ease-in 1s;
      /* transition: ease-out 1s; */
      position: absolute;
      width: 0;
      height: 0;
      z-index: -1;
      opacity: 0;
    }
  }
  
  .navbar.nav.fixed {
    position: fixed;
    top: 0;
    right: calc((100vw - 1140px) / 2 - 45px);
    z-index: 2000;
    padding-top: 20px;
    animation: navIn 0.5s ease;
  }
  
  @media (max-width: 1200px) {
    .navbar.nav.fixed {
      right: calc((100vw - 1140px) / 2 + 35px);
    }
  }
  
  @media (max-width: 1199px) {
    .navbar.nav.fixed {
      right: 0;
      animation: unset;
    }
  }
  
  .navbar.nav.fixed a {
    color: white;
  }
  
  .navbar.nav.fixed a::before {
    content: none !important;
  }
  
  .navbar.nav .nav-item a {
    position: relative;
    transition: all ease-in-out 0.25s;
  }
  
  .navbar.nav .nav-item a::before {
    transition: all ease-in-out 0.25s;
    width: 0;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    height: 1px;
    --grow-height: 20%;
  }
  
  .navbar.nav .nav-item.current:nth-child(1) a, .navbar.nav .nav-item:nth-child(1) a.active {
    color: var(--color-green-light);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(1) a:hover {
    color: var(--color-green-light);
  }
  
  .navbar.nav .nav-item:nth-child(1) a:hover::before {
    background-color: var(--color-green-light);
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
  }
  
  .navbar.nav .nav-item.current:nth-child(2) a, .navbar.nav .nav-item:nth-child(2) a.active {
    color: var(--color-blue-nav);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(2) a:hover {
    color: var(--color-blue-nav);
  }
  
    
  .navbar.nav .nav-item:nth-child(2) a:hover::before {
    background-color: var(--color-blue-nav);
  }
  
  .navbar.nav .nav-item:nth-child(2) a:hover::before, .navbar.nav .nav-item:nth-child(2) a:focus::before {
    background-color: var(--color-blue-nav);
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
  }
  
  .navbar.nav .nav-item.current:nth-child(3) a, .navbar.nav .nav-item:nth-child(3) a.active {
    color: var(--color-orange);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(3) a:hover {
    color: var(--color-orange);
  }
  
  .navbar.nav .nav-item:nth-child(3) a:hover::before {
    background-color: var(--color-orange);
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
  }
  
  .navbar.nav .nav-item.current:nth-child(4) a, .navbar.nav .nav-item:nth-child(4) a.active {
    color: var(--color-purple);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(4) a:hover, .navbar.nav .nav-item:nth-child(4) a:focus {
    color: var(--color-purple);
  }
  
  .navbar.nav .nav-item:nth-child(4) a:hover::before {
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
    background-color: var(--color-purple);
  }
  
  .navbar.nav .nav-item.current:nth-child(5) a, .navbar.nav .nav-item:nth-child(5) a.active {
    color: var(--color-light-blue);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(5) a:hover {
    color: var(--color-light-blue);
  }
  
  .navbar.nav .nav-item:nth-child(5) a:hover::before {
    background-color: var(--color-light-blue);
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
  }
  
  .navbar.nav .nav-item.current:nth-child(6) a, .navbar.nav .nav-item:nth-child(6) a.active {
    color: var(--color-red);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(6) a:hover {
    color: var(--color-red);
  }
  
  .navbar.nav .nav-item:nth-child(6) a:hover::before {
    background-color: var(--color-red);
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
  }
  
  .navbar.nav .nav-item.current:nth-child(7) a, .navbar.nav .nav-item:nth-child(7) a.active {
    color: var(--color-green);
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
  }
  
  .navbar.nav .nav-item:nth-child(7) a:hover {
    color: var(--color-green);
  }
  
  .navbar.nav .nav-item:nth-child(7) a:hover::before {
    background-color: var(--color-green);
    --grow-height: 20%;
    animation: growRight ease-in-out 0.25s;
  }
  
  @media (max-width: 1199px) {
    .navbar.nav .nav-item:nth-child(1) a:hover::before, .navbar.nav .nav-item:nth-child(2) a:hover::before, .navbar.nav .nav-item:nth-child(3) a:hover::before, .navbar.nav .nav-item:nth-child(4) a:hover::before, .navbar.nav .nav-item:nth-child(5) a:hover::before, .navbar.nav .nav-item:nth-child(6) a:hover::before, .navbar.nav .nav-item:nth-child(7) a:hover::before {
      content: none;
    }
    
    .navbar .navbar-nav {
     opacity: 0;
      margin-right: 0;
      width: 100vw;
      height: 100vh;
      text-align: center;
      padding-top: 70px;
    }
    
    .navbar .navbar-collapse.show .navbar-nav {
      --fade-opacity: 1;
      opacity: 1;
      animation: fadeIn ease-in-out 0.5s;
    }
    
    .navbar .navbar-nav li {
      margin-bottom: 20px;
    }
    
    .navbar .navbar-nav li a {
      padding-right: 0 !important;
      color: white !important;
    }
  }
  
  .navbar .navbar-collapse {
    justify-content: flex-end;
  }
  
  .navbar.show a:hover {
    color: var(--color-blue);
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
  }
  
  .nav-tabs {
    border-bottom: 2px solid transparent;
  }
  .nav-tabs .nav-item {
    margin-bottom: -2px;
  }
  .nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent transparent transparent;
  }
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: transparent;
    border-color: transparent transparent #F0AB00;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
  }
  
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  
  .navbar.nav {
    /* position: relative; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar .nav-link {
    font-size: 19px;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 30px !important;
  }
  
  @media (max-width: 1200px) {
    .navbar .nav-link {
      padding-right: 16px !important;
    }
  }
  
  @media (max-width: 991px) {
    .navbar .nav-link {
      font-size: 22px;
      font-family: "SAPRegular", Arial, Helvetica, sans-serif;
    }
  }
  
  .navbar .nav-item:last-child .nav-link {
    padding-right: 0 !important;
  }
  
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  @media (max-width: 576px) {
    .navbar-toggler {
      right: 35px;
    }
  }
  
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  
  .navbar-light .navbar-brand {
    color: var(--black);
  }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: var(--black);
  }
  .navbar-light .navbar-nav .nav-link {
    color: var(--black);
  }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    /* color: var(--black); */
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
/*   
  .navbar {
    display: none;
  }
   */
  
header .navbar {
    padding: 0;
  }
  
  header .bg_black.navbar {
    padding: 0;
  }
  
  header .navbar .navbar-brand {
    width: auto;
    height: 32px;
    padding: 0;
    margin: 0 10px 0 0;
    line-height: 32px;
    font-size: 32px;
  }
  header .navbar .navbar-brand .img-fluid {
    vertical-align: top;
    max-height: 100%;
    max-width: auto;
  }
  header .navbar .navbar-brand span {
    text-indent: -9000rem;
    display: inline-block;
  }
  header .navbar .title {
    font-size: 20px;
    color: #fff;
    text-align: left;
  }
  header .navbar > .bg_black {
    padding: 16px 1rem 15px;
    width: 100%;
    max-height: 64px;
  }
  header .navbar .navbar-toggler {
    color: white;
    border: 0;
    padding: 2px 0 0;
    margin: 0;
  }
  header .navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  header .navbar #navbarCollapse {
    background-color: rgba(0, 0, 0, 0.9);
    padding-bottom: 30px;
  }
  header .navbar #navbarCollapse .nav-item {
    margin: 15px 0;
    text-align: left;
  }
  header .navbar #navbarCollapse .nav-item.toc {
    font-size: 58px;
    color: #fff;
    padding: 0;
    line-height: 1.1;
    text-align: center;
  }
  header .navbar #navbarCollapse .nav-item .nav-link {
    font-size: 27px;
    color: #fff;
    padding: 0;
  }
  header .navbar #navbarCollapse .nav-item .nav-link.active, header .navbar #navbarCollapse .nav-item .nav-link:hover {
    color: #F0AB00;
  }