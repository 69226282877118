.callout {
    position: relative;
    margin-top: 62px;
}

.callout .callout-image {
    
}

.callout .callout-image img {
    object-fit: cover;
    object-position: center;
    height: 620px;
    width: 100%;
}

.callout .bubbles > li {
	position: absolute;
	display: block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: var(--color-blue);
}

.callout .bubbles > li:nth-child(1) {
	width: 20px;
	height: 20px;
	right: -74px;
	top: 79%;
    background-color: var(--color-green-light);
    animation: circleDownRight 18s alternate infinite;
}

.callout .bubbles > li:nth-child(2) {
	width: 50px;
	height: 50px;
    right: -23px;
    bottom: 52px;
    background-color: var(--color-orange);
}

.callout .bubbles > li:nth-child(3) {
	width: 30px;
	height: 30px;
	right: 22%;
	bottom: -30px;
    background-color: var(--color-light-blue);
    animation: circleDownLeft 15s alternate infinite;
}

.callout .bubbles > li:nth-child(4) {
	width: 20px;
	height: 20px;
	right: 10%;
	bottom: 4px;
    background-color: var(--color-green);
}

.callout .bubbles > li:nth-child(5) {
	width: 21px;
	height: 21px;
	right: -19%;
	bottom: -10px;
    background-color: var(--color-red);
    animation: pulseMove 20s alternate infinite;
}

.block.outro.two {
    margin: 0;
}

.gradient-circle {
    position: absolute;
    bottom: -159px;
    left: 198px;
}

.gradient-circle.outro {
    position: relative;
    left: 25%;
    bottom: 0;
    opacity: 0;
}

.block.outro.two.animate .gradient-circle.outro {
	--fade-opacity: 1;
	/* animation: 3s ease 0s normal forwards 1 scale; */
    animation: 7s ease 0s normal forwards 1 fadeIn52, 7s ease 0s normal forwards 1 bubbleScale;
    z-index: 99;
    opacity: 0;
}

.block.outro, .block.two.outro {
    overflow-x: clip;
    padding-top: 130px;
}

@media (hover: none) and (pointer: coarse) {
    .block.outro, .block.two.outro {
        overflow: hidden;
    }
}

.gradient-circle.outro > div {
    position: absolute;
    top: 50%;
    left: 150px;
    transform: translateY(-50%);
    z-index: 99;
}

.gradient-circle.outro p {
    color: white;
}

.gradient-circle.outro .col-7 > div {
    margin-bottom: 50px;
}

.gradient-circle.outro .btn.btn-primary.white {
    background-color: white;
    color: black;
    border: none;
    width: 100%;
    transition: all ease-in-out 0.25s;
}

.gradient-circle.outro .btn.btn-primary.white:hover {
    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.3);
    font-family: "SAPBook", Arial, Helvetica, sans-serif;
    transition: all ease-in-out 0.25s;
}

.gradient-circle.outro a {
    margin-bottom: 20px;
}

.gradient-circle.outro a:last-child {
    margin-bottom: 0;
}

.gradient-circle.outro::before {
    width: 700px;
    height: 700px;
}

.gradient-circle::before {
    content: '';
    background-image: 
    linear-gradient(to bottom, rgb(0,171,231), 
                               rgb(0,168,228), 
                               rgb(0,163,224),
                               rgb(6,156,218),
                               rgb(25,147,210),
                               rgb(47,136,202),
                               rgb(69,123,193),
                               rgb(94,107,183),
                               rgb(118,89,173),
                               rgb(144,67,162),
                               rgb(169,43,151));
    background-image: 
    -moz-linear-gradient(-82deg, rgb(0,171,231), 
                               rgb(0,168,228), 
                               rgb(0,163,224),
                               rgb(6,156,218),
                               rgb(25,147,210),
                               rgb(47,136,202),
                               rgb(69,123,193),
                               rgb(94,107,183),
                               rgb(118,89,173),
                               rgb(144,67,162),
                               rgb(169,43,151));
    transform: rotate(-39.5deg);
    background-repeat: no-repeat;
    width: 490px;
    height: 490px;
    opacity: 1;
    border-radius: 100%;
    position: relative;
    display: block;
    left: 0;
    top: 0;
}

.gradient-circle h1 {
    position: absolute;
    left: 108px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    color: var(--white);
    font-family: "SAPLight", Arial, Helvetica, sans-serif;
    line-height: 58px;
    letter-spacing: 0.08rem;
    z-index: 22;
    margin-bottom: 0;
}

.gradient-circle h1 b {
    font-size: 1.563rem;
    font-family: "SAPRegular", Arial, Helvetica, sans-serif;
    display: block;
    line-height: 33px;
    letter-spacing: 0.08rem;
}

.gradient-circle h1 b:nth-child(1) {
    margin-bottom: 5px;
}

.gradient-circle h1 b:last-child {
    margin-bottom: 0;
}

.block {
    position: relative;
}

#introduction .bubble-fixed {
    width: 12px;
	height: 12px;
	right: 25%;
    opacity: 1;
    background-color: var(--color-blue);
	border-radius: 50%;
	position: sticky;
    top: 80vh;
	transform: translateY(4vh) translateX(285px);
}

.intro {
    padding-top: 160px;
}

.quote h2, h4 {
    font-size: 28px;
    font-family: "SAPMedium", Arial, Helvetica, sans-serif;
    margin-bottom: 24px;
}

.quote p {
    font-size: 27px;
    font-family: "SAPLight", Arial, Helvetica, sans-serif;
    line-height: 44px;
}

.quote p span {
    font-size: 20px;
    font-family: "SAPRegular", Arial, Helvetica, sans-serif;
    margin-top: 12px;
    display: block;
    color: black;
}

.bubbles-mobile {
    display: none;
}

.col-8 p:last-child, .col-7 p:last-child {
    margin-bottom: 0;
}

@media (min-width: 2560px) {
    #introduction .bubble-fixed {
        transform: translateY(8vh) translateX(-2vw);
    }
}

@media (max-width: 1785px) {
    .gradient-circle {
        left: 120px;
    }
}

@media (max-width: 1600px) {
    
    .callout .callout-image img {
        height: 420px;
    }
    
    .gradient-circle h1 {
        left: 68px;
    }
    
    .block.you .bubbles-mobile {
        display: block;
    }

    
    .introduction {
        padding-top: 207px;
    }
    
    .block.you .col-7 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }
    
    .gradient-circle::before {
        width: 400px;
        height: 400px;
    }
}

@media (max-width: 1440px) {
    .gradient-circle {
        left: 60px;
    }

}

@media (max-width: 1300px) {
    .block {
        overflow-x: clip;
        overflow-y: visible;
    }
}

@media (max-width: 1200px) {
    .gradient-circle {
        left: -40px;
    }
}

@media (max-width: 1024px) {
    .gradient-circle.outro {
        left: 13%;
    }
}

@media (max-width: 991px) {
    #introduction .bubble-fixed {
        transform: translateY(0vh) translateX(192px);
        top: 66vh;
    }
    
    .gradient-circle {
        left: -40px;
    }
    
    .gradient-circle.outro {
        left: 1%;
        /* display: none; */
    }
    
    .gradient-circle h1 b {
        font-size: 22px;
        font-family: "SAPRegular", Arial, Helvetica, sans-serif;
    }
    
    .row .col-7, .row .col-8 {
        box-shadow: 1px 1px 23px -9px rgba(0, 0, 0, 0.25);
        background-color: white;
        border-radius: 12px;
        padding: 35px 30px;
        z-index: 99;
    }
    
    .highlight .row .col-8, .gradient-circle .col-7 {
        box-shadow: unset;
        background-color: unset;
        border-radius: unset;
        padding: auto;
    }
    
    .block {
        overflow-x: visible;
    }
        
    .block.workplace .col-7 {
        flex: 75%;
        max-width: 75%;
        left: 0;
    }
        
    .block.you .col-7 {
        padding-bottom: 65px;
    }
        
    .block.workplace .col-5 {
        flex: 25%;
        max-width: 25%;
    }
        
    .block.ecosystem .col-4 {
        flex: 25%;
        max-width: 25%;
    }
    
    .col-8 {
        flex: 75%;
        max-width: 75%;
    }
    
    .block .bubbles.hideMobile {
        display: none;
    }
        
    .block.values .bubbles-mobile, .block.business .bubbles-mobile, .block.society .bubbles-mobile, .block.workplace .bubbles-mobile, .block.ecosystem .bubbles-mobile {
        display: block;
    }
    
    .block.intro {
        padding-top: 205px;
    }
    
    .block.intro .col-5 {
        flex: 32%;
        max-width: 32%;
    }
    
    .col-7.quote {
        flex: 68%;
        max-width: 68%;
    }
    
    
    .quote h2, h4 {
        font-size: 25px;
    }
    
    .quote p {
        font-size: 22px;
        line-height: 34px;
    }
    
    .quote p span {
        font-size: 18px;
        line-height: 26px;
    }
}

@media (max-width: 576px) {
    .callout .callout-image {
        height: 300px;
        background-position: center;
    }
    
    .callout .callout-image img {
        height: 258px;
    }
    
    .callout .bubbles > li:nth-child(1) {
        right: -1%;
        top: 47%;
    }
    
    #introduction .bubble-fixed {
        transform: translateY(-7vh) translateX(192px);
        top: 40vh;
    }
    
    .gradient-circle.outro {
        left: 0;
    }
    
    .gradient-circle.outro::before {
        left: 50%;
        transform: translateX(-50%);
        width: 500px;
        height: 500px;
    }
    
    .gradient-circle {
        left: 0;
        bottom: -189px;
    }
    
    .gradient-circle::before {
        width: 300px;
        height: 300px;
        left: -28px;
    }
    
    .gradient-circle h1 {
        left: 30px;
        margin-right: 55px;
    }
    
    .gradient-circle h1 > b {
        /* font-size: 18px !important; */
    }
    
    .quote h2, h4 {
        font-size: 22px;
        margin-bottom: 24px;
    }
    
    .quote p {
        font-size: 20px;
        line-height: 28px;
    }
    
    .quote p span {
        font-size: 18px;
        margin-top: 12px;
        line-height: 24px;
    }
    
    .block {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    .block.intro {
        padding-top: 280px;
    }
    
    .block.intro .quote {
        flex: 100%;
        max-width: 100%;
    }
    
    .block.you .col-7 {
        padding-bottom: 60px;
    }
    
    .col-8, .col-7, .block.workplace .col-7, .block.workplace .col-5, .block.you .col-7 {
        flex: 100%;
        max-width: 100%;
        left: 0% !important;
        margin-left: 0 !important;
    }
    
    .row {
        display: block;
    }
    
    .block.values .col-8, .block.workplace .col-7, .block.ecosystem .col-8 {
        left: 10%;
    }
    
    .block.you .col-7.offset-1 {
        left: 0%;
    }
}