.bubbles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	transform: translateZ(0);
	list-style: none;
}

.block.values.active .bubbles.blue > li {
	position: absolute;
	display: block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	transition-timing-function: linear;
	background-color: var(--color-blue);
	/* animation: pulse 4s alternate infinite; */
}

.block.values.active .bubbles.blue > li:nth-child(1) {
	--fade-opacity: 0.1;
	width: 18px;
	height: 18px;
	right: 6%;
	bottom: 45%;
	animation: 3s ease 0s normal forwards 1 fadeIn, circleDownLeft 20s alternate infinite;
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(1) {
		bottom: auto;
		top: -13%;
		right: 28%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(2) {
	--fade-opacity: 0.8;
	bottom: 14%;
	left: 50%;
	width: 10px;
	height: 10px;
	animation: 3s ease 0s normal forwards 1 fadeIn60, circleDownRight 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(2) {
		left: 67%;
		animation: 2s ease 0s normal forwards 1 fadeIn60, circleDownRight 20s alternate infinite;
    }
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(2) {
		bottom: 8%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(3) {
	--fade-opacity: 0.45;
	top: 26%;
	left: 18%;
	width: 25px;
	height: 25px;
	animation: 4s ease 0s normal forwards 1 fadeIn25, pulse 6s alternate infinite 4s;
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(3) {
		right: 50%;
		left: auto;
		top: 0%;
		animation: 2s ease 0s normal forwards 1 fadeIn25, pulse 6s alternate infinite 4s;
    }
}

.block.values.active .bubbles.blue > li:nth-child(4) {
	--fade-opacity: 0.5;
	animation: 3s ease 0s normal forwards 1 fadeIn35, circleUpRight 20s alternate infinite;
	bottom: 46%;
	left: 26%;
	width: 18px;
	height: 18px;
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(4) {
		animation: 2s ease 0s normal forwards 1 fadeIn35, circleUpRight 20s alternate infinite;
		left: auto;
		right: 24%;
    }
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(4) {
		right: 0%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(5) {
	--fade-opacity: 0.25;
	animation: 5s ease 0s normal forwards 1 fadeIn52;
	bottom: 15%;
	right: 20%;
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(5) {
		left: -6%;
		bottom: 47%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(6) {
	--fade-opacity: 1;
	animation: 3s ease 0s normal forwards 1 fadeIn80, pulse 10s alternate infinite 4s;
	left: 28%;
	bottom: 22%;
	width: 65px;
	height: 65px;
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(6) {
		left: auto;
		right: 10%;
		bottom: 69%;
		animation: 2s ease 0s normal forwards 1 fadeIn80, pulse 10s alternate infinite 4s;
    }
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(6) {
		animation: 2s ease 0s normal forwards 1 fadeIn25, pulse 10s;
		left: 10%;
		top: 6%;
		bottom: auto;
    }
}

.block.values.active .bubbles.blue > li:nth-child(7) {
	--fade-opacity: 0.75;
	animation: 3s ease 0s normal forwards 1 fadeIn25, circleUp 20s alternate infinite;
	top: 5%;
	right: 22%;
}

.block.values.active .bubbles.blue > li:nth-child(8) {
	--fade-opacity: 0.25;
	animation: 2s ease 0s normal forwards 1 fadeIn25, circleUp 20s alternate infinite;
	top: -39%;
	left: -18%;
	width: 20px;
	height: 20px;
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(8) {
		left: auto;
		top: auto;
		right: 18%;
		bottom: 33%;
    }
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(8) {
		top: auto;
		bottom: -4%;
		left: 35%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(9) {
	--fade-opacity: 0.65;
	animation: 2s ease 0s normal forwards 1 fadeIn, circleDownLeft 20s alternate infinite;
	top: -20%;
	left: 34%;
	width: 40px;
	height: 40px;
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(9) {
		left: 13%;
		top: -4%;
	}
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(9) {
		animation: 2s ease 0s normal forwards 1 fadeIn25, pulse 10s;
		left: 36%;
		top: -13%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(10) {
	--fade-opacity: 0.25;
	animation: 1s ease 0s normal forwards 1 fadeIn, circleDownLeft 20s alternate infinite;
	top: -44%;
	left: -54%;
	width: 12px;
	height: 12px;
	animation-delay: 0s;
}

@media (max-width: 575px) {
	.block.values.active .bubbles.blue > li:nth-child(10) {
		top: auto;
		bottom: 24%;
		left: -1%;
    }
}

.block.values.active .bubbles.blue > li:nth-child(11) {
	--fade-opacity: 1;
	animation: 5s ease 0s normal forwards 1 fadeIn80, circleDownLeft 20s alternate infinite;
	bottom: -18%;
	left: 29%;
	width: 12px;
	height: 12px;
	animation-delay: 0s;
}

@media (max-width: 1024px) {
	.block.values.active .bubbles.blue > li:nth-child(11) {
		width: 18px;
		height: 18px;
		right: 6%;
	}
}

@media (max-width: 991px) {
	.block.values.active .bubbles.blue > li:nth-child(11) {
		animation: 2s ease 0s normal forwards 1 fadeIn80, circleDownLeft 20s alternate infinite;
		top: 9%;
		bottom: auto;
		left: 71%;
    }
}

.block.workplace.active .bubbles.purple > li {
	position: absolute;
	list-style: none;
	display: block;
	background-color: var(--color-purple);
	border-radius: 50%;
	bottom: -100px;
	transition-timing-function: linear;
}

.block.workplace.active .bubbles.purple > li:nth-child(1) {
	--fade-opacity: 0.75;
	width: 48px;
	height: 48px;
	right: 20%;
	top: 23%;
	animation: 2s ease 0s normal forwards 1 fadeIn40, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(1) {
		right: auto;
		left: 20%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(2) {
	--fade-opacity: 0.65;
	background-color: var(--color-orange);
	width: 20px;
	height: 20px;
	right: 52%;
	top: -17%;
	animation: 1s ease 0s normal forwards 1 fadeIn, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(2) {
		right: auto;
		left: 10%;
		top: 4%;
    }
}

@media (max-width: 576px) {
	.block.workplace.active .bubbles.purple > li:nth-child(2) {
		left: 3%;
		top: 5%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(3) {
	--fade-opacity: 1;
	background-color: var(--color-orange);
	width: 17px;
	height: 17px;
	left: 15%;
	top: -20%;
	animation: 2s ease 0s normal forwards 1 fadeIn, circleUpRight 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(3) {
		left: 15%;
		top: 4%;
    }
}

@media (max-width: 576px) {
	.block.workplace.active .bubbles.purple > li:nth-child(3) {
		left: 21%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(4) {
	--fade-opacity: 1;
	background-color: #E48479;
	width: 22px;
	height: 22px;
	left: 25%;
	top: -1%;
	animation: 2s ease 0s normal forwards 1 fadeIn35, pulse 15s alternate infinite;	
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(4) {
		right: 73%;
		top: 11%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(5) {
	--fade-opacity: 0.4;
	width: 19px;
	height: 19px;
	right: 60%;
	top: 21%;
	animation: 3s ease 0s normal forwards 1 fadeIn35, circleDown 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(5) {
		right: 60%;
		top: 7%;
    }
}

@media (max-width: 576px) {
	.block.workplace.active .bubbles.purple > li:nth-child(5) {
		right: 46%;
		top: 10%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(6) {
	--fade-opacity: 1;
	width: 12px;
	height: 12px;
	left: 62%;
	top: 11%;
	animation: 3s ease 0s normal forwards 1 fadeIn, pulse 15s alternate infinite;
}

@media (max-width: 576px) {
	.block.workplace.active .bubbles.purple > li:nth-child(6) {
		top: 2%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(7) {
	--fade-opacity: 1;
	width: 85px;
	height: 85px;
	left: 51%;
	top: 34%;
	animation: 4s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(7) {
		right: 6%;
		left: auto;
		top: 6%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(8) {
	--fade-opacity: 0.65;
	width: 21px;
	height: 21px;
	left: 39%;
	top: 52%;
	animation: 5s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(8) {
		right: auto;
		left: 30%;
		top: 43%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(9) {
	--fade-opacity: 0.65;
	width: 21px;
	height: 21px;
	left: 4%;
	top: 53%;
	animation: 8s ease 0s normal forwards 1 fadeIn75, pulse 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(10) {
	--fade-opacity: 1;
	width: 12px;
	height: 12px;
	right: 13%;
	top: 63%;
	animation: 10s ease 0s normal forwards 1 fadeIn35, circleUpRight 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(11) {
	--fade-opacity: 0.65;
	width: 21px;
	height: 21px;
	left: 24%;
	top: 70%;
	animation: 9s ease 0s normal forwards 1 fadeIn25, circleDown 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(12) {
	--fade-opacity: 0.38;
	width: 19px;
	height: 19px;
	left: 59%;
	top: 72%;
	animation: 10s ease 0s normal forwards 1 fadeIn75, circleUpRight 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.workplace.active .bubbles.purple > li:nth-child(12) {
		right: auto;
		left: 18%;
		top: 59%;
    }
}

.block.workplace.active .bubbles.purple > li:nth-child(13) {
	--fade-opacity: 1;
	width: 12px;
	height: 12px;
	left: 46%;
	top: 79%;
	animation: 8s ease 0s normal forwards 1 fadeIn, pulse 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(14) {
	--fade-opacity: 0.38;
	width: 19px;
	height: 19px;
	left: 53%;
	top: 89%;
	animation: 9s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(15) {
	--fade-opacity: 1;
	width: 12px;
	height: 12px;
	left: 64%;
	top: 86%;
	animation: 12s ease 0s normal forwards 1 fadeIn, circleUp 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(16) {
	--fade-opacity: 0.7;
	width: 30px;
	height: 30px;
	left: 56%;
	bottom: -12%;
	animation: 6s ease 0s normal forwards 1 fadeIn40, pulse 15s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(17) {
	--fade-opacity: 0.3;
	width: 12px;
	height: 12px;
	left: 17%;
	bottom: -23%;
	animation: 16s ease 0s normal forwards 1 fadeIn, circleUp 28s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(18) {
	--fade-opacity: 0.48;
	background-color: var(--color-light-blue);
	width: 12px;
	height: 12px;
	left: 48%;
	bottom: -28%;
	animation: 12s ease 0s normal forwards 1 fadeIn80, circleDownRight 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(19) {
	--fade-opacity: 1;
	background-color: #8BADDE;
	width: 14px;
	height: 14px;
	right: 12%;
	bottom: -21%;
	animation: 14s ease 0s normal forwards 1 fadeIn, pulse 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(20) {
	--fade-opacity: 0.6;
	width: 30px;
	height: 30px;
	right: -32%;
	bottom: -21%;
	animation: 15s ease 0s normal forwards 1 fadeIn40, circleDownLeft 20s alternate infinite;
}

.block.workplace.active .bubbles.purple > li:nth-child(21) {
	--fade-opacity: 0.3;
	width: 12px;
	height: 12px;
	right: -53%;
	bottom: -15%;
	animation: 16s ease 0s normal forwards 1 fadeIn, circleDownLeft 20s alternate infinite;
}

@media (max-width: 1024px) {
	.block.workplace.active .bubbles.purple > li:nth-child(21) {
		right: 10%;
		bottom: -21%;
	}
}

.block.ecosystem.active .bubbles.red > li {
	position: absolute;
	list-style: none;
	display: block;
	background-color: var(--color-red);
	border-radius: 50%;
	bottom: -100px;
}

.block.ecosystem.active .bubbles.red > li:nth-child(1) {
	--fade-opacity: 1;
	background-color: var(--color-light-blue);
	width: 21px;
	height: 21px;
	left: -36%;
	top: -30%;
	animation: 1s ease 0s normal forwards 1 fadeIn, circleUp 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(1) {
		top: 2%;
		left: 6%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(1) {
		width: 12px;
		height: 12px;
		top: 2%;
		left: 15%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(2) {
	--fade-opacity: 1;
	background-color: var(--color-light-blue);
	width: 19px;
	height: 19px;
	left: 5%;
	top: -20%;
	animation: 2s ease 0s normal forwards 1 fadeIn25, circleDownRight 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(2) {
		top: 9%;
		left: 1%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(2) {
		top: 8%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(3) {
	--fade-opacity: 0.28;
	background-color: var(--color-light-blue);
	width: 48px;
	height: 48px;
	left: 32%;
	top: -37%;
	animation: 3s ease 0s normal forwards 1 fadeIn35, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(3) {
		top: 3%;
		left: 15%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(3) {
		left: 23%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(4) {
	--fade-opacity: 1;
	background-color: #8F9FA8;
	width: 21px;
	height: 21px;
	right: 32%;
	top: -20%;
	animation: 4s ease 0s normal forwards 1 fadeIn40, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(4) {
		top: 7%;
		left: 33%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(4) {
		left: 59%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(5) {
	--fade-opacity: 1;
	background-color: #AA7F75;
	width: 21px;
	height: 21px;
	right: 49%;
	top: -5%;
	animation: 5s ease 0s normal forwards 1 fadeIn25, circleDown 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(5) {
		top: 18%;
		right: 65%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(5) {
		top: 9%;
		right: 19%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(6) {
	--fade-opacity: 1;
	width: 12px;
	height: 12px;
	left: 5%;
	top: 15%;
	animation: 6s ease 0s normal forwards 1 fadeIn25, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(6) {
		top: 24%;
		left: 23%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(6) {
		top: 11%;
		right: 29%;
		left: auto;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(7) {
	--fade-opacity: 1;
	width: 90px;
	height: 90px;
	left: 32%;
	bottom: 42%;
	animation: 7s ease 0s normal forwards 1 fadeIn25, circleUp 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(7) {
		top: 38%;
		bottom: auto;
		left: 32%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(7) {
		top: 38%;
		right: -4%;
		left: auto;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(8) {
	--fade-opacity: 1;
	width: 19px;
	height: 19px;
	left: -10%;
	bottom: 36%;
	animation: 5s ease 0s normal forwards 1 fadeIn75, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(8) {
		bottom: 36%;
		left: auto;
		right: 10%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(8) {
		bottom: 36%;
		right: -6%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(9) {
	--fade-opacity: 0.38;
	width: 19px;
	height: 19px;
	right: 32%;
	bottom: 21%;
	animation: 7s ease 0s normal forwards 1 fadeIn52, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(9) {
		bottom: auto;
		top: 59%;
		left: 16%;
		right: auto;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(9) {
		left: 0%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(10) {
	--fade-opacity: 1;
	width: 12px;
	height: 12px;
	right: 56%;
	bottom: 10%;
	animation: 5s ease 0s normal forwards 1 fadeIn40, circleUpRight 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(10) {
		bottom: 30%;
		left: 19%;
		right: auto;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(10) {
		bottom: 24%;
		left: -7%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(11) {
	--fade-opacity: 0.39;
	width: 19px;
	height: 19px;
	right: 46%;
	bottom: -2%;
	animation: 11s ease 0s normal forwards 1 fadeIn80, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(11) {
		bottom: 0%;
		right: auto;
		left: 24%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(12) {
	--fade-opacity: 0.39;
	width: 20px;
	height: 20px;
	right: 11%;
	bottom: -17%;
	animation: 8s ease 0s normal forwards 1 fadeIn25, pulse 15s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(12) {
		bottom: 23%;
		right: 13%;
    }
}

@media (max-width: 575px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(12) {
		--fade-opacity: 0.65;
		width: 12px;
		height: 12px;
		bottom: 10%;
		left: -1%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(13) {
	--fade-opacity: 0.39;
	width: 32px;
	height: 32px;
	right: -18%;
	bottom: -36%;
	animation: 7s ease 0s normal forwards 1 fadeIn75, circleUp 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.ecosystem.active .bubbles.red > li:nth-child(13) {
		bottom: 0%;
		right: 30%;
    }
}

.block.ecosystem.active .bubbles.red > li:nth-child(14) {
	--fade-opacity: 0.39;
	width: 19px;
	height: 19px;
	right: -40%;
	bottom: -28%;
	animation: 7s ease 0s normal forwards 1 fadeIn, pulse 16s alternate infinite;
}

.block.ecosystem.active .bubbles.red > li:nth-child(15) {
	--fade-opacity: 1;
	background-color: #B7AB87;
	width: 19px;
	height: 19px;
	right: -91%;
	bottom: -39%;
	animation: 4s ease 0s normal forwards 1 fadeIn80, circleDownLeft 20s alternate infinite;
}

.block.you.active .bubbles.orange li {
	position: absolute;
	list-style: none;
	display: block;
	transition-timing-function: linear;
	background-color: var(--color-orange);
	border-radius: 50%;
}

.block.you.active .bubbles.orange > li:nth-child(1) {
	--fade-opacity: 0.4;
	width: 10px;
	height: 10px;
	right: 85%;
	top: -8%;
	animation: 1s ease 0s normal forwards 1 fadeIn, pulse 4s alternate infinite;
}

.block.you.active .bubbles.orange > li:nth-child(2) {
	--fade-opacity: 0.85;
    top: -50px;
	left: -8%;
    width: 20px;
	height: 20px;
	animation: 2s ease 0s normal forwards 1 fadeIn35, circleDownLeft 10s alternate infinite;
}

@media (max-width: 1440px) {
	.block.you.active .bubbles.orange > li:nth-child(2) {
		top: 41px;
		left: -18%;
	}
}

.block.you.active .bubbles.orange > li:nth-child(3) {
	--fade-opacity: 0.15;
    top: -60px;
	left: 79%;
	width: 30px;
	height: 30px;
	animation: 4s ease 0s normal forwards 1 fadeIn, pulse 20s alternate infinite;
}

.block.you.active .bubbles.orange > li:nth-child(4) {
	--fade-opacity: 0.9;
    top: -29px;
    right: 33%;
	width: 12px;
	height: 12px;
	animation: 2s ease 0s normal forwards 1 fadeIn75, circleUpRight 22s alternate infinite;
}

.block.you.active .bubbles.orange > li:nth-child(5) {
	--fade-opacity: 0.7;
	top: 0;
	right: 15%;
	width: 45px;
	height: 45px;
	animation: 3s ease 0s normal forwards 1 fadeIn75, pulse 5s alternate infinite;
}

@media (max-width: 1440px) {
	.block.you.active .bubbles.orange > li:nth-child(5) {
		display: none;
	}
}

@media (max-width: 1200px) {
	.block.you.active .bubbles.orange > li:nth-child(5) {
		display: block;
		right: 42%;
	}
}

@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(5) {
        top: -64px;
    }
}


.block.you.active .bubbles.orange > li:nth-child(6) {
	--fade-opacity: 1;
	top: 1%;
	right: -37%;
	width: 10px;
	height: 10px;
	animation: 3s ease 0s normal forwards 1 fadeIn35, circleDownLeft 11s alternate infinite;
}

@media (max-width: 1600px) {
	.block.you.active .bubbles.orange > li:nth-child(6) {
		display: none;
	}
}

@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(6) {
		display: block;
		top: auto;
		bottom: -9%;
		right: -1%;
    }
}

.block.you.active .bubbles.orange > li:nth-child(7) {
	--fade-opacity: 0.9;
	top: 15%;
	right: 38%;
	width: 21px;
	height: 21px;
	animation: 5s ease 0s normal forwards 1 fadeIn35, pulse 20s alternate infinite;
}


@media (max-width: 1600px) {
	.block.you.active .bubbles.orange > li:nth-child(7) {
		display: none;
	}
}

@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(7) {
		display: block;
		top: auto;
		bottom: -7%;
		right: auto;
		left: 22%;
    }
}

.block.you.active .bubbles.orange > li:nth-child(8) {
	--fade-opacity: 1;
	top: 23%;
	left: 84%;
	width: 75px;
	height: 75px;
	animation: 4s ease 0s normal forwards 1 fadeIn75, circleUpRight 22s alternate infinite;
}

@media (max-width: 1600px) {
	.block.you.active .bubbles.orange > li:nth-child(8) {
		display: block;
		top: -2%;
		left: 30%;
	}
}

@media (max-width: 1200px) {
	.block.you.active .bubbles.orange > li:nth-child(8) {
		display: none;
	}
}


@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(8) {
		--fade-opacity: 0.65;
		display: block;
		top: auto;
		bottom: 9%;
		left: 2px;
    }
}

.block.you.active .bubbles.orange > li:nth-child(9) {
	--fade-opacity: 0.35;
	top: 20%;
	right: -31%;
	width: 20px;
	height: 20px;
	animation: 3s ease 0s normal forwards 1 fadeIn80, pulse 5s alternate infinite;
}

@media (max-width: 1600px) {
	.block.you.active .bubbles.orange > li:nth-child(9) {
		top: 5%;
		right: 4%;
	}
}

@media (max-width: 992px) {
	.block.you.active .bubbles.orange > li:nth-child(9) {
		right: 19%;
    }
}

.block.you.active .bubbles.orange > li:nth-child(10) {
	--fade-opacity: 0.25;
	top: 29%;
	right: -12%;
	width: 30px;
	height: 30px;
	animation: 4s ease 0s normal forwards 1 fadeIn80, circleDownLeft 10s alternate infinite;
}

@media (max-width: 1200px) {
	.block.you.active .bubbles.orange > li:nth-child(10) {
		--fade-opacity: 0.65;
		top: 2%;
		right: 13%;
		width: 20px;
		height: 20px;
	}
}

@media (max-width: 1024px) {
	.block.you.active .bubbles.orange > li:nth-child(10) {
		display: none;
	}
}

@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(10) {
		display: block;
		top: 25%;
		right: auto;
		left: 3%;
    }
}

.block.you.active .bubbles.orange > li:nth-child(11) {
	--fade-opacity: 0.45;
	top: 40%;
	left: -189%;
	width: 24px;
	height: 24px;
	animation: 4s ease 0s normal forwards 1 fadeIn80, circleDownLeft 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(11) {
		left: 0%;
    }
}

.block.you.active .bubbles.orange > li:nth-child(12) {
	--fade-opacity: 0.95;
	top: 50%;
	left: -196%;
	width: 40px;
	height: 40px;
	animation: 3s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

@media (max-width: 1024px) {
	.block.you.active .bubbles.orange > li:nth-child(12) {
		display: none;
	}
}


@media (max-width: 991px) {
	.block.you.active .bubbles.orange > li:nth-child(12) {
		display: block;
		top: auto;
		bottom: -54px;
		left: 13%;
    }
}

.block.you.active .bubbles.orange > li:nth-child(13) {
	--fade-opacity: 0.45;
	bottom: 10%;
	left: -196%;
	width: 28px;
	height: 28px;
	animation: 6s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

.block.you.active .bubbles.orange > li:nth-child(14) {
	--fade-opacity: 0.5;
	bottom: -6%;
	left: -153%;
	width: 10px;
	height: 10px;
	animation: 7s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

.block.you.active .bubbles.orange > li:nth-child(15) {
	--fade-opacity: 0.85;
	bottom: -10%;
	left: -147%;
	width: 42px;
	height: 42px;
	animation: 7s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

.bubbles.blue-light li {
	position: absolute;
	list-style: none;
	display: block;
	background-color: var(--color-light-blue);
	border-radius: 50%;
	transition-timing-function: linear;
}

.block.business.active .bubbles.blue-light li:nth-child(1) {
	--fade-opacity: 1;
    top: -24%;
	width: 10px;
	height: 10px;
	left: 31%;
	animation: 1s ease 0s normal forwards 1 fadeIn, circleDown 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(1) {
		top: -6%;
		left: 31%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(2) {
	--fade-opacity: 1;
    top: 14%;
    width: 75px;
	height: 75px;
	left: 24%;
	animation: 5s ease 0s normal forwards 1 fadeIn52, pulse 18s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(2) {
		top: 0%;
		left: 15%;
    }
}

@media (max-width: 575px) {
	.block.business.active .bubbles.blue-light li:nth-child(2) {
		animation: 4s ease 0s normal forwards 1 fadeIn52, pulse 18s alternate infinite;
		top: 0%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(3) {
	--fade-opacity: 0.85;
    top: 13%;
	width: 15px;
	height: 15px;
	right: 30%;
	animation: 3s ease 0s normal forwards 1 fadeIn40, pulse 18s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(3) {
		top: 45%;
		right: 12%;
    }
}

@media (max-width: 575px) {
	.block.business.active .bubbles.blue-light li:nth-child(3) {
		top: 21%;
		right: -2%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(4) {
	--fade-opacity: 1;
    top: 27%;
	width: 10px;
	height: 10px;
	left: auto;
    right: 33%;
	animation: 4s ease 0s normal forwards 1 fadeIn52, circleDown 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(4) {
		top: 5%;
		right: 20%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(5) {
	--fade-opacity: 1;
	top: 4%;
	width: 20px;
	height: 20px;
	right: 8%;
	animation: 5s ease 0s normal forwards 1 fadeIn25, pulse 20s alternate infinite;
}

.block.business.active .bubbles.blue-light li:nth-child(6) {
	--fade-opacity: 0.65;
	top: 65%;
	width: 21px;
	height: 21px;
	right: 52%;
	animation: 5s ease 0s normal forwards 1 fadeIn80, circleDown 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(6) {
		top: 17%;
		right: 15%;
    }
}

@media (max-width: 575px) {
	.block.business.active .bubbles.blue-light li:nth-child(6) {
		top: 15%;
		right: 25%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(7) {
	--fade-opacity: 0.75;
	top: 33%;
	width: 21px;
	height: 21px;
	right: -12%;
	animation: 7s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.business.active .bubbles.blue-light li:nth-child(7) {
		right: 14%;
	}
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(7) {
		width: 34px;
		height: 34px;
		top: 26%;
		right: 20%;
    }
}

@media (max-width: 575px) {
	.block.business.active .bubbles.blue-light li:nth-child(6) {
		right: auto;
		left: 0%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(8) {
	--fade-opacity: 0.7;
	width: 7px;
	height: 7px;
	left: 47%;
	animation: 1s ease 0s normal forwards 1 fadeIn25, pulse 20s alternate infinite;
}

@media (max-width: 576px) {
	.block.business.active .bubbles.blue-light li:nth-child(8) {
		width: 18px;
		height: 18px;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(9) {
	--fade-opacity: 0.7;
	top: 34%;
	width: 10px;
	height: 10px;
	right: -1%;
	animation: 9s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

@media (max-width: 576px) {
	.block.business.active .bubbles.blue-light li:nth-child(9) {
		width: 35px;
		height: 35px;
    }
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(9) {
		top: 17%;
		right: 7%;
    }
}

@media (max-width: 575px) {
	.block.business.active .bubbles.blue-light li:nth-child(9) {
		top: auto;
		bottom: 10%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(10) {
	--fade-opacity: 1;
	top: 43%;
	width: 8px;
	height: 8px;
	left: 39%;
	animation: 7s ease 0s normal forwards 1 fadeIn25, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(10) {
		top: auto;
		bottom: 6%;
		right: 26%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(11) {
	--fade-opacity: 1;
	top: 40%;
	width: 15px;
	height: 15px;
	left: 29%;
	animation: 6s ease 0s normal forwards 1 fadeIn40, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(11) {
		top: auto;
		bottom: 3%;
		left: 29%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(12) {
	--fade-opacity: 1;
	top: 49%;
	width: 10px;
	height: 10px;
	right: 12%;
	animation: 6s ease 0s normal forwards 1 fadeIn52, pulse 20s alternate infinite;
}


@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(12) {
		top: auto;
		bottom: 0%;
		right: 44%;
    }
}

.block.business.active .bubbles.blue-light li:nth-child(13) {
	--fade-opacity: 1;
	top: -14%;
	width: 18px;
	height: 18px;
	left: 7%;
	animation: 1s ease 0s normal forwards 1 fadeIn52, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.business.active .bubbles.blue-light li:nth-child(13) {
		top: auto;
		bottom: 22%;
		left: 14%;
    }
}

@media (max-width: 575px) {
	.block.business.active .bubbles.blue-light li:nth-child(13) {
		top: auto;
		bottom: 13%;
		left: 14%;
    }
}

.block.society.active .bubbles.green li {
	position: absolute;
	list-style: none;
	display: block;
	transition-timing-function: linear;
	background-color: var(--color-green);
	border-radius: 50%;
}

.block.society.active .bubbles.green li:nth-child(1) {
	--fade-opacity: 0.39;
	top: -7%;
	width: 19px;
	height: 19px;
	left: 38%;
	animation: 1s ease 0s normal forwards 1 fadeIn, circleDown 20s alternate infinite;
}

.block.society.active .bubbles.green li:nth-child(2) {
	--fade-opacity: 0.39;
	top: 28%;
	width: 19px;
	height: 19px;
	left: 52%;
	animation: 2s ease 0s normal forwards 1 fadeIn35, pulse 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.society.active .bubbles.green li:nth-child(2) {
		left: 9%;
    }
}

@media (max-width: 575px) {
	.block.society.active .bubbles.green li:nth-child(2) {
		left: 12%;
		top: 9%;
    }
}

.block.society.active .bubbles.green li:nth-child(3) {
	--fade-opacity: 1;
	top: 40%;
	width: 90px;
	height: 90px;
	right: 3%;
	animation: 3s ease 0s normal forwards 1 fadeIn52, circleDownRight 20s alternate infinite;
}

@media (max-width: 991px) {
	.block.society.active .bubbles.green li:nth-child(3) {
		right: 16%;
    }
}

@media (max-width: 575px) {
	.block.society.active .bubbles.green li:nth-child(3) {
		left: -5%;
		right: auto;
    }
}

.block.society.active .bubbles.green li:nth-child(4) {
	--fade-opacity: 0.39;
	bottom: 29%;
	width: 19px;
	height: 19px;
	right: 20%;
	animation: 4s ease 0s normal forwards 1 fadeIn40, pulse 18s alternate infinite;
}

@media (max-width: 575px) {
	.block.society.active .bubbles.green li:nth-child(4) {
		right: -2%;
    }
}

.block.society.active .bubbles.green li:nth-child(5) {
	--fade-opacity: 1;
	bottom: 10%;
	width: 12px;
	height: 12px;
	right: -12%;
	animation: 5s ease 0s normal forwards 1 fadeIn, circleUpRight 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.society.active .bubbles.green li:nth-child(5) {
		right: 18%;
	}
}

@media (max-width: 1024px) {
	.block.society.active .bubbles.green li:nth-child(5) {
		right: 16%;
	}
}

@media (max-width: 575px) {
	.block.society.active .bubbles.green li:nth-child(5) {
		right: 0%;
    }
}

.block.society.active .bubbles.green li:nth-child(6) {
	--fade-opacity: 0.39;
	bottom: -4%;
	width: 19px;
	height: 19px;
	right: -40%;
	animation: 6s ease 0s normal forwards 1 fadeIn60, circleDownLeft 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.society.active .bubbles.green li:nth-child(6) {
		right: 42%;
	}
}

@media (max-width: 1024px) {
	.block.society.active .bubbles.green li:nth-child(6) {
		bottom: 7%;
		right: 32%;
	}
}

.block.society.active .bubbles.green li:nth-child(7) {
	--fade-opacity: 1;
	bottom: -13%;
	width: 12px;
	height: 12px;
	right: -29%;
	animation: 7s ease 0s normal forwards 1 fadeIn75, circleDownLeft 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.society.active .bubbles.green li:nth-child(7) {
		right: 12%;
	}
}

@media (max-width: 1024px) {
	.block.society.active .bubbles.green li:nth-child(7) {
		display: none;
	}
}

@media (max-width: 991px) {
	.block.society.active .bubbles.green li:nth-child(7) {
		display: block;
		right: 10%;
		bottom: 45%;
    }
}

@media (max-width: 575px) {
	.block.society.active .bubbles.green li:nth-child(7) {
		bottom: 5%;
    }
}

.block.society.active .bubbles.green li:nth-child(8) {
	--fade-opacity: 0.85;
	top: -13%;
	width: 19px;
	height: 19px;
	right: -9%;
	animation: 2s ease 0s normal forwards 1 fadeIn25, circleDownLeft 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.society.active .bubbles.green li:nth-child(8) {
		right: 11%;
	}
}

@media (max-width: 1024px) {
	.block.society.active .bubbles.green li:nth-child(8) {
		right: 2%;
	}
}

@media (max-width: 991px) {
	.block.society.active .bubbles.green li:nth-child(8) {
		top: 12%;
		right: 6%;
    }
}

.block.society.active .bubbles.green li:nth-child(9) {
	--fade-opacity: 0.65;
	top: 18%;
	width: 40px;
	height: 40px;
	right: -28%;
	animation: 3s ease 0s normal forwards 1 fadeIn25, circleUp 14s alternate infinite;
}

@media (max-width: 1200px) {
	.block.society.active .bubbles.green li:nth-child(9) {
		right: 7%;
	}
}

@media (max-width: 1024px) {
	.block.society.active .bubbles.green li:nth-child(9) {
		top: 15%;
		right: 24%;
	}
}

@media (max-width: 575px) {
	.block.society.active .bubbles.green li:nth-child(9) {
		top: 10%;
		right: 38%;
    }
}

.block.society.active .bubbles.green li:nth-child(10) {
	--fade-opacity: 1;
	top: -21%;
	width: 12px;
	height: 12px;
	left: 56%;
	animation: 3s ease 0s normal forwards 1 fadeIn25, pulse 14s alternate infinite;
}

.block.outro.animate .bubbles.outro li {
	position: absolute;
	list-style: none;
	display: block;
	transition-timing-function: linear;
	border-radius: 50%;
}

.block.outro.animate .bubbles.outro li:nth-child(1) {
	--fade-opacity: 1;
	background-color: var(--color-green);
	top: 0;
	width: 19px;
	height: 19px;
	right: 13%;
	animation: 2s ease 0s normal forwards 1 fadeIn, circleDown 20s alternate infinite;
}

.block.outro.animate .bubbles.outro li:nth-child(2) {
	--fade-opacity: 1;
	background-color: var(--color-green);
	--top-position: 7%;
	--right-position: 27%;
	width: 19px;
	height: 19px;
	animation: 10s ease 0s normal forwards 1 bubbleMoveCenterRight;
}

.block.outro.animate .bubbles.outro li:nth-child(3) {
	--fade-opacity: 1;
	--right-position: 22%;
	--top-position: 8%;
	background-color: var(--color-orange);
	width: 48px;
	height: 48px;
	animation: 8s ease 0s normal forwards 1 bubbleMoveCenterRight60;
}

.block.outro.animate .bubbles.outro li:nth-child(4) {
	--fade-opacity: 1;
	background-color: var(--color-light-blue);
	--top-position: 16%;
	--right-position: 16%;
	width: 30px;
	height: 30px;
	animation: 8s ease 0s normal forwards 1 bubbleMoveCenterRight60;
}

.block.outro.animate .bubbles.outro li:nth-child(5) {
	--fade-opacity: 1;
	background-color: var(--color-red);
	--top-position: 17%;
	--right-position: 27%;
	width: 22px;
	height: 22px;
	animation: 8s ease 0s normal forwards 1 bubbleMoveCenterRight35;
}

.block.outro.animate .bubbles.outro li:nth-child(6) {	
	--fade-opacity: 1;
	background-color: var(--color-blue);
	--top-position: 22%;
	--right-position: 22%;
	width: 12px;
	height: 12px;
	animation: 8s ease 0s normal forwards 1 bubbleMoveCenterRight35;
}

.block.outro.animate .bubbles.outro li:nth-child(7) {
	--fade-opacity: 1;
	background-color: var(--color-light-blue);
	top: 14%;
	width: 30px;
	height: 30px;
	left: 32%;
	animation: 13s ease 0s normal forwards 1 fadeIn52, 10s ease 0s normal forwards 1 move;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(7) {
        left: 23%;
    }
}

.block.outro.animate .bubbles.outro li:nth-child(8) {
	--fade-opacity: 1;
	background-color: var(--color-orange);
	top: 20%;
	width: 14px;
	height: 14px;
	left: 30%;
	animation: 13s ease 0s normal forwards 1 fadeIn60, 5s ease 0s normal forwards 1 move;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(8) {
        left: 19%;
    }
}

.block.outro.animate .bubbles.outro li:nth-child(9) {
	--fade-opacity: 1;
	background-color: var(--color-green-light);
	top: 22%;
	width: 19px;
	height: 19px;
	left: 27%;
	animation: 13s ease 0s normal forwards 1 fadeIn25;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(9) {
		top: 16%;
        left: 16%;
    }
}

.block.outro.animate .bubbles.outro li:nth-child(10) {
	--fade-opacity: 1;
	background-color: var(--color-blue);
	top: 26%;
	width: 12px;
	height: 12px;
	--top-position: 26%;
	--left-position: 32%;
	left: 32%;
	animation: 13s ease 0s normal forwards 1 fadeIn80;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(10) {
        left: 9%;
    }
}

.block.outro.animate .bubbles.outro li:nth-child(11) {
	--fade-opacity: 1;
	background-color: var(--color-red);
	--top-position: 35%;
	top: 35%;
	left: 28%;
	--left-position: 28%;
	/* top: 35%; */
	width: 21px;
	height: 21px;
	/* left: 28%; */
	animation: 13s ease 0s normal forwards 1 fadeIn52, circleDown 20s alternate infinite;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(11) {
        left: 12%;
    }
}

.block.outro.animate .bubbles.outro li:nth-child(12) {
	--fade-opacity: 1;
	background-color: var(--color-green);
	--top-position: 29%;
	top: 29%;
	left: 21%;
	--left-position: 21%;
	width: 10px;
	height: 10px;
	animation: 13s ease 0s normal forwards 1 fadeIn25;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(12) {
        left: 14%;
		top: 24%;
    }
}


.block.outro.animate .bubbles.outro li:nth-child(13) {
	--fade-opacity: 1;
	background-color: var(--color-purple);
	top: 28%;
	left: 29%;
	width: 10px;
	height: 10px;
	animation:  13s ease 0s normal forwards 1 fadeIn80;
}

@media (max-width: 1024px) {
	.block.outro.animate .bubbles.outro li:nth-child(13) {
        left: 17%;
    }
}

/* .block.outro.animate .bubbles.outro li:nth-child(14) {
	--fade-opacity: 1;
	background-color: var(--color-light-blue);
	--top-position: 18%;
	--right-position: 70%;
	width: 40px;
	height: 40px;
	animation:  4s ease 0s normal forwards 1 bubbleMoveCenterRight;
} */

/* .block.outro.animate .bubbles.outro li:nth-child(15) {
	--fade-opacity: 1;
	background-color: var(--color-orange);
	--top-position: 30%;
	--left-position: 53%;
	width: 30px;
	height: 30px;
	animation:  3s ease 0s normal forwards 1 bubbleMoveCenterLeft60;
} */

/* .block.outro.active .bubbles.outro li:nth-child(16) {
	--fade-opacity: 1;
	background-color: var(--color-blue);
	--top-position: 60%;
	--right-position: 35%;
	width: 40px;
	height: 40px;
	animation:  3s ease 0s normal forwards 1 bubbleMoveCenterRight;
} */
/* 
.block.outro.animate .bubbles.outro li:nth-child(17) {
	--fade-opacity: 1;
	background-color: var(--color-red);
	--top-position: 66%;
	--left-position: 80%;
	width: 20px;
	height: 20px;
	animation:  3s ease 0s normal forwards 1 bubbleMoveCenterLeftExra;
}

.block.outro.animate .bubbles.outro li:nth-child(18) {
	--fade-opacity: 1;
	background-color: var(--color-green);
	--top-position: 10%;
	--left-position: 60%;
	width: 15px;
	height: 15px;
	animation:  3s ease 0s normal forwards 1 bubbleMoveCenterLeft60;
}

 */
 
 .bubble-fixed.blue {
	width: 19px;
	height: 19px;
	border-radius: 50%;
	opacity: 0.85;
	background-color: var(--color-light-blue);
	position: sticky;
	top: 30vh;
	transform: translateY(198px)  translateX(51vw);
 }

.block.business.active .bubble-fixed.blue {
	animation: 5s ease 0s normal forwards 1 fadeIn25;
}

@media (max-width: 1440px) {
	.block.business.active .bubble-fixed.blue {
		transform: translateY(198px)  translateX(69vw);
	}
}

@media (max-width: 1200px) {
	.block.business.active .bubble-fixed.blue {
		transform: translateY(198px)  translateX(78vw);
	}
}

@media (max-width: 991px) {
	.block.business.active .bubble-fixed.blue {
		transform: translateY(198px)  translateX(74vw);
	}
}

@media (max-width: 576px) {
	.block.business.active .bubble-fixed.blue {
		transform: translateY(198px)  translateX(82vw);
	}
}

.bubble-fixed.orange {
	position: sticky;
	top: -10vh;
	transform: translateY(60vh) translateX(-3vw);
	width: 15px;
	height: 15px;
	border-radius: 50%;
	opacity: 0.85;
	background-color: var(--color-orange);
}

@media (max-width: 576px) {
	.bubble-fixed.orange {
		transform: translateY(60vh) translateX(-8vw);
	}
}

.block.you.active .bubble-fixed.orange {
	animation: 8s ease 0s normal forwards 1 fadeIn25;
}

.block.outro.two.animate .bubbles.two li {
	position: absolute;
	list-style: none;
	display: block;
	transition-timing-function: linear;
	border-radius: 50%;
}

.block.outro.two.animate .bubbles.two li:nth-child(1) {
	--fade-opacity: 1;
	--var-top: 0;
	--var-right: 19%;
	background-color: var(--color-green);
	top: 13px;
	width: 19px;
	height: 19px;
	right: 19%;
	animation: 8s ease 0s normal forwards 1 fadeIn, circleDown 20s alternate infinite;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(2) {
	--fade-opacity: 1;
	--var-top: 7%;
	--var-right: 27%;
	background-color: var(--color-green-light);
	top: 7%;
	width: 19px;
	height: 19px;
	right: 27%;
	animation: 8s ease 0s normal forwards 1 fadeIn25, circleUp 20s alternate infinite;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(3) {
	--fade-opacity: 1;
	background-color: var(--color-orange);
	top: 8%;
	width: 48px;
	height: 48px;
	right: 22%;
	animation: 8s ease 0s normal forwards 1 fadeIn, pulse 20s alternate infinite;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(4) {
	--fade-opacity: 1;
	background-color: var( --color-light-blue);
	top: 16%;
	width: 30px;
	height: 30px;
	right: 16%;
	animation: 8s ease 0s normal forwards 1 fadeIn25, circleUpRight 20s alternate infinite;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(5) {
	--fade-opacity: 1;
	background-color: var(--color-red);
	top: 17%;
	width: 22px;
	height: 22px;
	right: 27%;
	animation: 14s ease 0s normal forwards 1 fadeIn40, pulse 20s alternate infinite;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(6) {
	--fade-opacity: 1;
	background-color: var(--color-blue);
	top: 22%;
	width: 12px;
	height: 12px;
	right: 22%;
	animation: 14s ease 0s normal forwards 1 fadeIn80, pulse 20s alternate infinite;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(7) {
	--fade-opacity: 1;
	--var-top: 14%;
	--var-left: 32%;
	background-color: var(--color-light-blue);
	top: 14%;
	width: 30px;
	height: 30px;
	left: 32%;
	animation: 16s ease 0s normal forwards 1 fadeIn52, circleDown 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.outro.two.animate .bubbles.two li:nth-child(7) {
		top: 10%;
	}
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(7) {
		top: 7%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(8) {
	--fade-opacity: 1;
	background-color: var(--color-orange);
	top: 23%;
	width: 14px;
	height: 14px;
	left: 30%;
	animation: 14s ease 0s normal forwards 1 fadeIn52, circleDown 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.outro.two.animate .bubbles.two li:nth-child(8) {
		top: 17%;
	}
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(8) {
		top: 10%;
		left: 43%;
	}
}

@media (max-width: 576px) {
	.block.outro.two.animate .bubbles.two li:nth-child(8) {
		top: 13%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(9) {
	--fade-opacity: 1;
	background-color: var(--color-green-light);
	top: 22%;
	width: 19px;
	height: 19px;
	left: 27%;
	animation: 14s ease 0s normal forwards 1 fadeIn60;
}

@media (max-width: 1200px) {
	.block.outro.two.animate .bubbles.two li:nth-child(9) {
		top: 16%;
		left: 22%;
	}
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(9) {
		top: 13%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(10) {
	--fade-opacity: 1;
	background-color: var(--color-blue);
	top: 26%;
	width: 12px;
	height: 12px;
	--top-position: 26%;
	--left-position: 32%;
	left: 32%;
	animation: 14s ease 0s normal forwards 1 fadeIn80;
}

@media (max-width: 1200px) {
	.block.outro.two.animate .bubbles.two li:nth-child(10) {
		top: 12%;
		left: 39%;
	}
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(10) {
		top: 10%;
		left: 52%;
	}
}


@media (max-width: 576px) {
	.block.outro.two.animate .bubbles.two li:nth-child(10) {
		top: 14%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(11) {
	--fade-opacity: 1;
	background-color: var(--color-red);
	--top-position: 35%;
	top: 35%;
	left: 28%;
	--left-position: 28%;
	/* top: 35%; */
	width: 21px;
	height: 21px;
	/* left: 28%; */
	animation: 14s ease 0s normal forwards 1 fadeIn52, circleDown 20s alternate infinite;
}

@media (max-width: 1200px) {
	.block.outro.two.animate .bubbles.two li:nth-child(11) {
		left: 12%;
	}
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(11) {
		top: 11%;
		left: 10%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(12) {
	--fade-opacity: 1;
	background-color: var(--color-green);
	--top-position: 29%;
	top: 29%;
	left: 21%;
	--left-position: 21%;
	width: 10px;
	height: 10px;
	animation: 12s ease 0s normal forwards 1 fadeIn80;
}

@media (max-width: 1200px) {
	.block.outro.two.animate .bubbles.two li:nth-child(12) {
		top: 28%;
		left: 17%;
	}
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(12) {
		top: 15%;
		left: 16%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(13) {
	--fade-opacity: 1;
	background-color: var(--color-purple);
	--top-position: 29%;
	top: 15%;
	right: 21%;
	--left-position: 21%;
	width: 30px;
	height: 30px;
	animation: 10s ease 0s normal forwards 1 fadeIn25;
	display: none;
}

.block.outro.two.animate .bubbles.two li:nth-child(14) {
	--fade-opacity: 1;
	background-color: var(--color-purple);
	--top-position: 20%;
	--left-position: 31%;
	width: 80px;
	height: 80px;
	animation:  8s ease 0s normal forwards 1 BubbleFadeLeft40;
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(14) {
		--top-position: 18%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(15) {
	--fade-opacity: 1;
	background-color: var(--color-light-blue);
	--top-position: 36%;
	--right-position: 53%;
	width: 80px;
	height: 80px;
	animation: 8s ease 0s normal forwards 1 BubbleFadeRight25;
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(15) {
		--top-position: 38%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(16) {
	--fade-opacity: 1;
	background-color: var(--color-blue);
	--top-position: 45%;
	--right-position: 50%;
	width: 40px;
	height: 40px;
	animation: 8s ease 0s normal forwards 1 BubbleFadeRight30;
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(16) {
		--top-position: 52%;
	}
}

.block.outro.two.animate .bubbles.two li:nth-child(17) {
	--fade-opacity: 1;
	background-color: var(--color-orange);
	--top-position: 5%;
	--left-position: 48%;
	width: 20px;
	height: 20px;
	animation: 8s ease 0s normal forwards 1 BubbleFadeLeft40;
}

@media (max-width: 991px) {
	.block.outro.two.animate .bubbles.two li:nth-child(17) {
		--top-position: 2%;
	}
}


.block.outro.two.animate .bubbles.two li:nth-child(18) {
	--fade-opacity: 1;
	background-color: var(--color-red);
	--top-position: 60%;
	--left-position: 40%;
	width: 20px;
	height: 20px;
	animation: 8s ease 0s normal forwards 1 BubbleFadeLeft40;
}

.block.outro.two.animate .bubbles.two li:nth-child(19) {
	--fade-opacity: 1;
	background-color: var(--color-green);
	--top-position: 12%;
	--right-position: 58%;
	width: 20px;
	height: 20px;
	animation: 8s ease 0s normal forwards 1 BubbleFadeRight30;
}

.block.outro.two.animate .bubbles.two li:nth-child(20) {
	--fade-opacity: 1;
	background-color: var(--color-green-light);
	--top-position: 32%;
	--left-position: 45%;
	width: 20px;
	height: 20px;
	animation: 8s ease 0s normal forwards 1 BubbleFadeLeft40;
}