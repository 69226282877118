@keyframes circleUp {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
	}
}

@keyframes circleDown {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes circleDownRight {
	0% {
		-webkit-transform: translateY(-100px) rotate(800deg);
		transform: translateY(-10px) rotate(800deg);
	}
	100% {
		-webkit-transform: translateY(0) translateX(+20px);
		transform: translateY(0) translateX(+20px);
	}
}

@keyframes circleDownLeft {
	0% {
		-webkit-transform: translateY(-100px) rotate(800deg);
		transform: translateY(-10px) rotate(800deg);
	}
	100% {
		-webkit-transform: translateY(0) translateX(-20px);
		transform: translateY(0) translateX(-20px);
	}
}

@keyframes circleUpRight {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-50px) translateX(+20px);
		transform: translateY(-50px) translateX(+20px);
	}
}

@keyframes navIn {
	0% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes navOut {
	0% {
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(1.4);
		transform: scale(1.4);
	}
}

@keyframes fading {
    from {
		transform: translateY(-10px);
        opacity: 0;
    }
    to {
		transform: translateY(0);
		opacity: 1;   
    }
}


@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: var(--fade-opacity);
	}
}

@keyframes fadeIn80 {
    0% {
		opacity: 0;
	}
    80% {
		opacity: 0;
	}
    100% {
		opacity: var(--fade-opacity);
	}
}

@keyframes fadeIn75 {
    0% {
		opacity: 0;
	}
    75% {
		opacity: 0;
	}
    100% {
		opacity: var(--fade-opacity);;
	}
}

@keyframes fadeIn60 {
    0% {
		opacity: 0;
	}
    60% {
		opacity: 0;
	}
    100% {
		opacity: var(--fade-opacity);
	}
}

@keyframes fadeIn52 {
    0% {
		opacity: 0;
	}
    52% {
		opacity: 0;
	}
    100% { opacity: var(--fade-opacity);
	}
}

@keyframes fadeIn40 {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: var(--fade-opacity);
	}
}

@keyframes fadeIn35 {
    0% {
		opacity: 0;
	}
    35% {
		opacity: 0;
	}
    100% {
		opacity: var(--fade-opacity);
	}
}

@keyframes fadeIn25 {
    0% {
		opacity: 0;
	}
    25% {
		opacity: 0;
	}
    100% {
		opacity: var(--fade-opacity);
	}
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;   
        transform: translateX(0);
    }
}

@keyframes slideInOffset {
    from {
        opacity: 0;
        transform: translateX(-20px) translateY(50%);
    }
    to {
        opacity: 1;   
        transform: translateX(0) translateY(50%);
    }
}

@keyframes growRight {
    from {
        width: 0;
    }
    to {
        width: var(--grow-height);
    }
}

@keyframes growDown {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: 20px;
        opacity: 1;   
    }
}

@keyframes growDownLine {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: var(--grow-height);
        opacity: 1;   
    }
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}

@keyframes pulseMove {
	0% {
		-webkit-transform: scale(1) translateY(0) translateX(0);
		transform: scale(1) translateY(0) translateX(0);
	}
	55% {
		-webkit-transform: scale(1.2) translateY(-5px) translateX(-5px);
		transform: scale(1.2) translateY(-5px) translateX(-5px);
	}
	100% {
		-webkit-transform: scale(1) translateY(-15px) translateX(-20px);
		transform: scale(1) translateY(-15px) translateX(-20px);
	}
}

@keyframes center {
	0% {
		top: var(--top-position);
		left: var(--left-position);
	}
		
	30% {
		top: 50%;
		left: 50%;
		transform: scale(2) translateX(10px);
	}
	
	
	100% {
		top: var(--top-position);
		left: var(--left-position);
	}
}

@keyframes centerLeft {
	0% {
		top: var(--top-position);
		left: var(--left-position);
	}
		
	30% {
		top: 50%;
		left: 50%;
		transform: scale(2) translateX(10px);
	}
	
	
	100% {
		top: var(--top-position);
		left: var(--left-position);
		opacity: var(--fade-opacity);
	}
}

@keyframes bgScaleNav {
    0% {
		transform: translateX(0) translateY(0);
        border-radius: 100%;
        right: 10px;
        top: 10px;
        width: 0;
        height: 0;
        opacity: .95;
    }
        
    100% {
        transform: translateX(0) translateY(0) scale(4);
        right: 0;
		left: 0;
        top: 0;
        border-radius: 100%;
        width: 2560px;
        height: 2560px;
		opacity: .95;
    }
}

@keyframes bgScaleNavBack {
    0% {
		transform: translateX(0) translateY(0) scale(0);
        border-radius: 100%;
		width: 100vw;
		height: 100vh;
		opacity: 1;
    }
        
    100% {
        transform: translateX(0) translateY(0) scale(0);
		border-radius: 100%;
        width: 0;
        height: 0;
		opacity: 0;
    }
}

@keyframes scale {
    0% {
		transform: scale(0);
		opacity: 0;
    }
	
    30% {
		transform: scale(0) translateX(-2%) translateY(80%);
		opacity: 0;
    }
	
    100% {
        transform: scale(1);
		opacity: var(--fade-opacity);
    }
}

@keyframes bubbleMove {
	
	0% {
		transform: translateX(20px) translateY(0) scale(0);
	}
	25% {
		left: 10%;
		transform: translateX(50%) translateY(20px) scale(0);
		
	}
	35% {
		opacity: 1;
	}
	70% {
		top: 40%;
		left: 50%;
		transform: translateX(40px) translateY(50px) scale(6);
	}
	100% {
		left: 50%;
		transform: translateX(40px) translateY(50px) scale(0);
		opacity: 0.5;
	}
}
  
@keyframes bubbleScale {
	0% {
		transform: translateX(0) scale(0);
	}
	80% {
		transform: translateX(0) scale(0);
	}
	100% {
		transform: translateX(0) scale(1);
	}
}
  
@keyframes bubbleScaleZwo {
	0% {
		transform: translateX(0) scale(0);
	}
	40% {
		transform: translateX(0) scale(0);
	}
	100% {
		transform: translateX(0) scale(1);
	}
}
  
@keyframes BubbleFadeRight25 {
	0% {
		transform: scale(1);
		right: 50%;
		top: var(--top-position);
		opacity: 0;
	}
	
	15% {
		opacity: 1;
	}
	
	25% {
		right: 50%;
	}
	
	50% {
		right: var(--right-position);
		top: var(--top-position);
	}

	100% {
		top: var(--top-position);
		right: -50%;
		transform: scale(1) translateY(-20px) translateX(70px);
	}
}
  
@keyframes BubbleFadeRight30 {
	0% {
		transform: scale(1);
		right: 50%;
		top: var(--top-position);
		opacity: 0;
	}
	
	15% {
		opacity: 1;
		right: 50%;
		top: var(--top-position);
	}
	
	30% {
		right: 50%;
	}
	
	65% {
		right: var(--right-position);
		top: var(--top-position);
	}

	100% {
		top: var(--top-position);
		right: -50%;
		transform: scale(1) translateY(-20px) translateX(100px);
	}
}
  
@keyframes BubbleFadeLeft40 {
	0% {
		transform: scale(1);
		left: 50%;
		top: var(--top-position);
		opacity: 0;
	}
	
	15% {
		opacity: 1;
	}
	
	40% {
		left: 50%;
		top: var(--top-position);
	}
	
	70% {
		transform: scale(1);
		left: var(--left-position);
		top: var(--top-position);
	}

	100% {
		top: var(--top-position);
		left: -50%;
		transform: scale(1) translateY(80px) translateX(-300px);
	}
}
  
@keyframes BubbleFadeLeft70 {
	0% {
		transform: scale(1);
		left: 50%;
		top: var(--top-position);
		opacity: 0;
	}
	
	15% {
		opacity: 1;
	}
	
	70% {
		transform: scale(1);
		left: var(--left-position);
		top: var(--top-position);
	}

	100% {
		top: var(--top-position);
		left: -50%;
		transform: scale(1) translateY(80px) translateX(300px);
	}
}

@keyframes bgScaleLeft {
    0% {
        border-radius: 100%;
        left: var(--left-position);
        top: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }
    
    20% {
        opacity: .95;
    }
        
    100% {
        transform: translateX(0) translateY(0) scale(4);
        left: 0;
        top: 0;
        border-radius: 100%;
        width: 1000px;
        height: 1000px;
        opacity: .95;
    }
}

@keyframes bgScaleRight {
    0% {
        border-radius: 100%;
        right: var(--right-position);
        top: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }
    
    20% {
        opacity: .95;
    }
        
    100% {
        transform: translateX(0) translateY(0) scale(4);
        right: 0;
        top: 0;
        border-radius: 100%;
        width: 1000px;
        height: 1000px;
        opacity: .95;
    }
}

@keyframes bgScale {
    0% {
        border-radius: 100%;
        left: 20%;
        top: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }
        
    100% {
        transform: translateX(0) translateY(0) scale(4);
        left: 0;
        top: 0;
        border-radius: 100%;
        width: 2560px;
        height: 2560px;
        opacity: .85;
    }
}